import { ENUMS } from '../enums';

export const CUST_INFO = [
	{
		key: 'custId',
		description: '客户ID [最大15位, 每个用户都是独特]',
		example: '000000000010126',
		type: 'String',
	},
	{
		key: 'custIdIN',
		description: '转入客户ID [最大15位, 每个用户都是独特]',
		example: '000000000010126',
		type: 'String',
	},
	{
		key: 'custIdOUT',
		description: '转出客户ID [最大15位, 每个用户都是独特]',
		example: '000000000010126',
		type: 'String',
	},
	{
		key: 'nameEnc',
		description: '全名 [长度最大50, 全都大写单词, 名字 - 姓名, 示例: DOE JOHN]',
		example:
			'Rw8kE7nEZbJRNUIJOAwBz9Ijfc3bUBSSPaUPKQq5KAAFoBY69uXuDC33bwtOKVK8ivWzKL+SIWgJtGR6xfjaLEmsgJmoYnV2MqNwV+ypCwWlP6KKn+guvXVc/cq/+5Wa42o1MKIRlyKyODsO8ncFuE1cCjL+xE80wgbN1dC5o40=',
		encrypted: true,
		type: 'String',
	},
	{
		key: 'khNameEnc',
		description: '柬埔寨姓名 [姓 + " " + 名]',
		example: '',
		type: 'String',
		encrypted: true,
	},
	{
		key: 'nameDec',
		description: '解密全名 [长度最大50, 全都大写单词, 名字 - 姓名, 示例: DOE JOHN]',
		example: '',
		type: 'String',
	},
	{
		key: 'fullname',
		description: '姓名',
		example: '',
		type: 'Object',
		attributes: ['firstname', 'lastname'],
	},
	{
		key: 'firstname',
		description: '名字',
		example: '',
		type: 'String',
	},
	{
		key: 'lastname',
		description: '姓氏',
		example: '',
		type: 'String',
	},
	{
		key: 'addr',
		description: '地址 [长度最大200]',
		example: '',
		type: 'String',
	},
	{
		key: 'address',
		description: '地址 [长度最大200]',
		example: '',
		type: 'String',
	},
	{
		key: 'email',
		description: '电子邮箱 [长度最大50]',
		example: 'mail@bongloy.com',
		type: 'String',
	},
	{
		key: 'headPicUrlFlag',
		description: '是否上传头像照',
		example: 'Y',
		enum: ENUMS.flagEnum,
		type: 'String',
	},
	{
		key: 'idPhotos',
		description: '证件照',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['base64Data', 'originalFileName'],
	},
	{
		key: 'headPhotos',
		description: '头像照',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: ['base64Data', 'originalFileName'],
	},
	{
		key: 'base64Data',
		description: 'base64图片',
		example: '',
		type: 'String',
	},
	{
		key: 'originalFileName',
		description: '图片名称',
		example: '',
		type: 'String',
	},
	{
		key: 'dob',
		description: '出生日期 [yyyyMMdd]',
		example: '20221231',
		type: 'String',
	},
	{
		key: 'endDt',
		description: '卡有效期 [格式: yyyyMMdd]',
		example: '20220911',
		type: 'String',
	},
	{
		key: 'endDtOUT',
		description: '转出卡卡有效期',
		example: '',
		type: 'String',
	},
	{
		key: 'startDt',
		description: '起始时间',
		example: '',
		type: 'String',
	},
	{
		key: 'prdtNo',
		description: '账户类型',
		example: '0003',
		enum: ENUMS.currencyEnum,
		type: 'String',
	},
	{
		key: 'prdtNoIN',
		description: '转入账户类型',
		example: '0003',
		enum: ENUMS.currencyEnum,
		type: 'String',
	},
	{
		key: 'prdtNoOUT',
		description: '转出账户类型',
		example: '0003',
		enum: ENUMS.currencyEnum,
		type: 'String',
	},
	{
		key: 'sex',
		description: '性别',
		example: '',
		enum: ENUMS.sexEnum,
		type: 'String',
	},
	{
		key: 'occupation',
		description: '职业',
		example: '',
		type: 'String',
	},
];
