import { HTTP_METHODS, TAG } from '@/const';

export const UMTS = [
	{
		id: '4401',
		title: 'Money Express',
		titleCN: '国际汇款',
		endpoint: '/app/internationalRemittance',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		note: 'The payeeNameEnc is not required if the card region is Cambodia.',
		noteCN: 'payeeNameEnc不需要如果卡地区是柬埔寨',
		attributes: [
			'businessIndication',
			'custId',
			'externalTxnRefId',
			'fundSource',
			'note',
			'otherPurpose',
			'payeeNameEnc',
			'payeePanEnc',
			'payerExtCardId',
			'purpose',
			'txnAmt',
			'txnCurrency',
		],
		optionalAttributes: ['externalTxnRefId', 'note', 'otherPurpose'],
		respAttributes: [
			'cardIdIN4',
			'cardIdOUT4',
			'currAvailAtOUT',
			'currBalAtOUT',
			'externalTxnRefId',
			'fee',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'otherPurpose',
			'purpose',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'systemResponse',
			'txnAmt',
			'txnDt',
			'txnRefId',
			'txnTm',
			'txnType',
		],
	},
	{
		id: '4402',
		title: 'Query Money Express Transaction',
		titleCN: '国际汇款订单查询',
		endpoint: '/app/internationalRemittanceOrderQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: ['orderNo'],
		respAttributes: ['origResponseCode', 'origResponseMsg'],
	},
	{
		id: '4403',
		title: 'Money Express Online Transaction Init',
		titleCN: '国际汇款线上初始化',
		endpoint: '/app/moneyExpressOnlineTransactionInit',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: [
			'businessIndication',
			'externalTxnRefId',
			'fundSource',
			'note',
			'payeeNameEnc',
			'payeePanEnc',
			'payerExtCardId',
			'txnAmt',
			'txnCurrency',
		],
		optionalAttributes: ['externalTxnRefId', 'note'],
		respAttributes: [
			'cardIdIN4',
			'cardIdOUT4',
			'externalTxnRefId',
			'fee',
			'feeCurrency',
			'initTxnId',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'txnAmt',
		],
	},
	{
		id: '4404',
		title: 'Money Express Offline Transaction Init',
		titleCN: '国际汇款线下初始化',
		endpoint: '/app/moneyExpressOfflineTransactionInit',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: [
			'applicationOperator',
			'businessIndication',
			'externalTxnRefId',
			'fundSource',
			'note',
			'payeeNameEnc',
			'payeePanEnc',
			'payerExtCardId',
			'txnAmt',
			'txnCurrency',
		],
		optionalAttributes: ['externalTxnRefId', 'note'],
		respAttributes: [
			'cardIdIN4',
			'cardIdOUT4',
			'externalTxnRefId',
			'fee',
			'feeCurrency',
			'initTxnId',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'txnAmt',
		],
	},
	{
		id: '4405',
		title: 'Money Express Transaction Validation',
		titleCN: '国际汇款初始化订单确认',
		endpoint: '/app/moneyExpressTransactionValidation',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: ['initTxnId', 'otherPurpose', 'purpose', 'validateBy', 'validationResult'],
		optionalAttributes: ['otherPurpose'],
		respAttributes: [
			'cardIdIN4',
			'cardIdOUT4',
			'currAvailAtOUT',
			'currBalAtOUT',
			'externalTxnRefId',
			'fee',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'note',
			'originalTxnAmt',
			'originalTxnCurrency',
			'otherPurpose',
			'purpose',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'state',
			'systemResponse',
			'txnAmt',
			'txnDt',
			'txnRefId',
			'txnTm',
			'txnType',
			'validationResult',
		],
	},
	{
		id: 'N204',
		title: 'Query Money Express Service Fee',
		titleCN: '查询国际汇款业务费用',
		endpoint: '/app/umtsServiceFeeQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: [
			'businessIndication',
			'custId',
			'externalTxnRefId',
			'fundSource',
			'payeeNameEnc',
			'payeePanEnc',
			'payerExtCardId',
			'txnAmt',
			'txnCurrency',
		],
		respAttributes: [
			'fee',
			'feeCurrency',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
		],
	},
	{
		id: 'N205',
		title: 'Check International Remittance Account Balance',
		titleCN: '查询国际汇款账户余额',
		endpoint: '/app/umtsBalanceQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: [],
		respAttributes: ['accountType', 'balance', 'balanceSymbol', 'balanceType', 'currencyCode'],
	},
];
