import { HTTP_METHODS, TAG } from '@/const';

export const ACCOUNT = [
	{
		id: '2303',
		title: 'Open an Account for New User With Basic KYC',
		titleCN: '为新注册的无KYC用户开设账户',
		endpoint: '/app/newCustomerBasicCreate',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT,
		attributes: ['acctCurrencyType', 'extCustId', 'fullname', 'mobile', 'notes', 'referralCode'],
		optionalAttributes: ['acctCurrencyType', 'extCustId', 'notes', 'referralCode'],
		respAttributes: ['acctCurrencyType', 'acctId', 'custId', 'extCustId', 'notes', 'referralCode'],
	},
	{
		id: '2304',
		title: 'Upgrade Basic KYC to Full KYC',
		titleCN: '将非标准客户升级成标准客户',
		endpoint: '/app/customerTierUpgradeRequest',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT,
		attributes: [
			'address',
			'applicationOperator',
			'dob',
			'email',
			'extCustId',
			'fullname',
			'headPhotos',
			'idPhotos',
			'mobile',
			'nationalityCode',
			'notes',
			'occupation',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'sex',
			'zip',
		],
		optionalAttributes: ['email', 'referralCode', 'zip'],
		respAttributes: ['applicationId', 'custId', 'extCustId', 'referralCode'],
	},
	{
		id: '2305',
		title: 'Open an Account for New User With Full KYC',
		titleCN: '标准型客户申请开户',
		endpoint: '/app/newCustomerStandardCreate',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT,
		attributes: [
			'acctCurrencyType',
			'address',
			'applicationOperator',
			'dob',
			'email',
			'extCustId',
			'fullname',
			'headPhotos',
			'idPhotos',
			'mobile',
			'nationalityCode',
			'notes',
			'occupation',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'sex',
			'zip',
		],
		optionalAttributes: ['acctCurrencyType', 'email', 'referralCode', 'zip'],
		respAttributes: ['applicationId'],
	},
	{
		id: '2306',
		title: 'Preview a Customer Application',
		titleCN: '客户创建或升级申请单详情',
		endpoint: '/app/customerApplicationPreview',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT,
		attributes: ['applicationId'],
		respAttributes: [
			'acctCurrencyType',
			'address',
			'applicationOperator',
			'dob',
			'email',
			'extCustId',
			'fullname',
			'headPhotos',
			'idPhotos',
			'mobile',
			'nationalityCode',
			'notes',
			'occupation',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'sex',
			'zip',
		],
	},
	{
		id: '2307',
		title: 'Validate a Customer Application',
		titleCN: '标准型客户和升级单审核',
		endpoint: '/app/customerApplicationValidation',
		method: HTTP_METHODS.POST,
		tag: TAG.ACCOUNT,
		attributes: ['applicationId', 'notes', 'validatedBy', 'validationResult'],
		optionalAttributes: ['notes', 'validatedBy'],
		respAttributes: [
			'acctCurrencyType',
			'acctId',
			'applicationId',
			'custId',
			'extCustId',
			'notes',
			'referralCode',
			'validatedBy',
			'validationResult',
		],
	},
];
