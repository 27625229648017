import { VIRTUAL_CARD } from './virtual-card';
import { PHYSICAL_CARD } from './physical-card';
import { VERIFICATION } from './verification';
import { QUERY_CARDHOLDER_INFO } from './query-cardholder-info';
import { MODIFY_CARDHOLDER_INFO } from './modify-cardholder-info';
import { CARD_CONFIGURATION } from './card-configuration';
import { ACCOUNT } from './account';
import { ACCOUNT_CONFIGURATION } from './account-configuration';
import { INTERNAL_TRANSFER } from './internal-transfer';
import { BAKONG_TRANSACTION } from './bakong-transaction';
import { UMPS } from './umps';
import { UMTS } from './umts';
import { ANONYMOUS_CARD } from './anonymous-card';
import { OHTERS } from './others';
import { NOTIFICATION } from './notification';

export const ENDPOINTS = [
	...VIRTUAL_CARD,
	...PHYSICAL_CARD,
	...VERIFICATION,
	...QUERY_CARDHOLDER_INFO,
	...MODIFY_CARDHOLDER_INFO,
	...CARD_CONFIGURATION,
	...ACCOUNT,
	...ACCOUNT_CONFIGURATION,
	...INTERNAL_TRANSFER,
	...BAKONG_TRANSACTION,
	...UMPS,
	...UMTS,
	...ANONYMOUS_CARD,
	...OHTERS,
	...NOTIFICATION,
];
