import { VALUES } from "../values";

export const OPERATOR = [
	{
		key: 'operatorId',
		description: 'Recharge Operator ID',
		example: '',
		type: 'String',
	},
	{
		key: 'operatorNm',
		description: "Recharge Operator's Name",
		example: '',
		type: 'String',
	},
	{
		key: 'appliOpr',
		description: 'Application Operator (Maximum Length 20)',
		example: '',
		type: 'String',
	},
	{
		key: 'checkOpr',
		description: 'Audit Operator (Maximum Length 20)',
		example: '',
		type: 'String',
	},
	{
		key: 'note',
		description: 'Note (Maximum Length 256)',
		example: '',
		type: 'String',
	},
	{
		key: 'notes',
		description: 'Note (Maximum Length 256)',
		example: '',
		type: 'String',
	},
	{
		key: 'operationType',
		description: 'Operation Type',
		example: '',
		value: VALUES.operationTypeValue,
		type: 'String',
	},
];
