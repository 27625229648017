<template>
	<div class="side-bar-component">
		<div class="logo" :style="{ 'background-image': `url('/images/bongloy_logo.png')` }"></div>

		<div class="searchForm">
			<font-awesome-icon icon="fa-solid fa-magnifying-glass" class="search-icon" />
			<input
				id="searchInput"
				type="text"
				v-model="searchInput"
				ref="searchInput"
				:placeholder="
					currentLanguage == 'en' ? 'Search...' : currentLanguage == 'cn' && '搜索...'
				" />
			<font-awesome-icon
				icon="fa-solid fa-xmark"
				class="close-icon"
				size="lg"
				v-if="searchInput.length > 0"
				@click="onDeleteAll" />
			<div class="slash" v-if="searchInput.length === 0">/</div>
		</div>

		<div v-if="showMenu != false" class="menu">
			<div v-for="tag in tags" :key="tag.id">
				<div
					class="endpoint-tag"
					v-if="filteredEndpoints.filter((ep) => ep.tag.id == tag.id).length !== 0">
					<CardIcon v-if="tag.id === '1100' || tag.id === '1200' || tag.id === 'ANON'" />
					<VerificationIcon v-if="tag.id === '2100'" />
					<CardholderInfoIcon v-if="tag.id === '2200'" />
					<ModifyCardholderInfoIcon v-if="tag.id === '2300'" />
					<CardConfigIcon v-if="tag.id === '3100'" />
					<AccountIcon v-else-if="tag.id === '2300(1)'" />
					<WalletIcon v-if="tag.id === '3200'" />
					<TransferIcon v-if="tag.id === '4100'" />
					<BakongIcon v-if="tag.id === '4200'" />
					<UnionPayIcon v-if="tag.id === '4300'" />
					<MoneyExpressIcon v-if="tag.id === '4400'" />
					<OtherIcon v-if="tag.id === '0'" />
					<NotificationIcon v-if="tag.id === 'NTF'" />
					<p class="endpoint-tag-name">
						{{ tag.name }}
					</p>
				</div>
				<div
					class="item menu-item"
					v-for="endpoint in filteredEndpoints.filter((ep) => ep.tag.id === tag.id)"
					:key="endpoint.id">
					<p
						class="endpoint"
						:id="`menu-${endpoint.id}`"
						@click="goToRef(endpoint.id)"
						:title="endpoint.title">
						<span class="endpoint-id">{{ endpoint.id }}</span
						><span class="dash">-</span>{{ endpoint.title }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import { TAG } from '@/const';
import CardIcon from '../icons/CardIcon.vue';
import VerificationIcon from '../icons/VerificationIcon.vue';
import CardholderInfoIcon from '../icons/CardholderInfoIcon.vue';
import ModifyCardholderInfoIcon from '../icons/ModifyCardholderInfoIcon.vue';
import CardConfigIcon from '../icons/CardConfigIcon.vue';
import TransferIcon from '../icons/TransferIcon.vue';
import WalletIcon from '../icons/WalletIcon.vue';
import UnionPayIcon from '../icons/UnionPayIcon.vue';
import MoneyExpressIcon from '../icons/MoneyExpressIcon.vue';
import BakongIcon from '../icons/BakongIcon.vue';
import OtherIcon from '../icons/OtherIcon.vue';
import NotificationIcon from '../icons/NotificationIcon.vue';
import AccountIcon from '../icons/AccountIcon.vue';

export default {
	name: 'SideBarComponent',
	components: {
		CardIcon,
		VerificationIcon,
		CardholderInfoIcon,
		ModifyCardholderInfoIcon,
		CardConfigIcon,
		TransferIcon,
		AccountIcon,
		WalletIcon,
		UnionPayIcon,
		MoneyExpressIcon,
		BakongIcon,
		OtherIcon,
		NotificationIcon,
	},
	data() {
		return {
			tags: TAG,
			searchInput: ref(''),
		};
	},
	computed: {
		endpoints() {
			return this.$store.getters['client/endpoints/filterEndpoints'](
				this.$route.meta.include,
				this.$route.meta.exclude,
				this.$route.query.lang
			);
		},
		showMenu() {
			return this.$route.meta.showMenu;
		},
		currentLanguage() {
			return this.$route.query.lang || 'en';
		},
		filteredEndpoints() {
			if (this.searchInput == '') {
				return this.endpoints;
			} else {
				return this.endpoints.filter(
					(endpoint) =>
						endpoint.title.toLowerCase().includes(this.searchInput.toLowerCase()) ||
						endpoint.endpoint.toLowerCase().includes(this.searchInput.toLowerCase()) ||
						endpoint.id.includes(this.searchInput)
				);
			}
		},
	},
	methods: {
		goToRef(id) {
			var element = document.getElementById(id);
			var ctn = document.getElementById('endpoint-scroll-ctn');
			var top = element.offsetTop;
			ctn.scrollTo(0, top);
		},
		onKeyPress(event) {
			const activeElement = document.querySelector('#searchInput');

			if (event.key !== '/' && activeElement !== document.activeElement) {
				this.$emit('keyPressed', true);
				return;
			}

			if (document.activeElement == this.$refs.searchInput) {
				return;
			}

			event.preventDefault();
			this.$refs.searchInput.focus();
		},
		onDeleteAll() {
			document.getElementById('searchInput').value = '';
			this.searchInput = ref('');
			this.$refs.searchInput.focus();
		},
	},
	mounted() {
		if (this.showMenu == false) return;

		const ctn = document.getElementById('endpoint-scroll-ctn');
		const endpointIds = this.filteredEndpoints.map((e) => e.id);

		var done = false;
		const isInitial = endpointIds
			.map((endpoint) =>
				document.getElementById(`menu-${endpoint}`).getAttribute('data-scrolled-passed')
			)
			.every((element) => element == null);

		if (isInitial) {
			document.getElementById(`menu-${endpointIds[0]}`).setAttribute('data-scrolled-passed', true);
			done = true;
		}

		ctn.onscroll = () => {
			done = false;

			endpointIds.forEach((endpoint) => {
				const menuElem = document.getElementById(`menu-${endpoint}`);

				if (done) {
					menuElem?.setAttribute('data-scrolled-passed', false);
					return;
				}

				const elem = document.getElementById(endpoint);
				const vpOffset = elem.getBoundingClientRect().top + elem.offsetHeight - 75;

				menuElem?.setAttribute('data-scrolled-passed', false);

				if (vpOffset > 0) {
					menuElem?.setAttribute('data-scrolled-passed', true);
					done = true;
				}
			});
		};

		// implementing press "/" to focus the search input
		window.addEventListener('keypress', this.onKeyPress);
	},
};
</script>
