import { HTTP_METHODS, TAG } from '@/const';

export const OHTERS = [
	{
		id: 'N1',
		title: 'Open an Account With Custom Currency',
		titleCN: '根据币种开立账号',
		endpoint: '/app/accountOpen',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['custId', 'mobile', 'nameEnc', 'pidNoEnc', 'pidType', 'prdtNo'],
		respAttributes: ['acctId', 'custId'],
	},
	{
		id: 'N2',
		title: 'Card Re-binding Account',
		titleCN: '卡重新绑定账户',
		endpoint: '/app/cardRebindAccount',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: [
			'acctId',
			'cardIdEnc',
			'custId',
			'mobile',
			'nameEnc',
			'pidNoEnc',
			'pidType',
			'prdtNo',
		],
		respAttributes: ['acctId', 'currAvailAt', 'currBalAt', 'lockAt', 'prdtNo'],
	},
	{
		id: 'N3',
		title: 'Query Currency Information',
		titleCN: '查询货币代码信息',
		endpoint: '/app/queryCurrencyInfo',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['currCode', 'numCode'],
		optionalAttributes: ['currCode', 'numCode'],
		respAttributes: ['country', 'currCode', 'currName', 'numCode'],
	},
	{
		id: 'N4',
		title: 'Change Default Account',
		titleCN: '更改账号默认标签',
		endpoint: '/app/acctDefaultLabelChange',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['acctId', 'prdtNo'],
		respAttributes: ['acctId'],
	},
	{
		id: 'N5',
		title: 'Replace an Expired Card',
		titleCN: '过期卡补卡',
		endpoint: '/app/cardExpiredChgCard',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['custId', 'extCardId'],
		respAttributes: ['proRegNum'],
	},
	{
		id: 'N6',
		title: 'Money Conversion Calculation',
		titleCN: '货币转换计算',
		endpoint: '/app/moneyConversionCalculation',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['amt', 'dstCurrency', 'srcCurrency'],
		respAttributes: ['amt', 'dstCurrency', 'srcCurrency'],
	},
	{
		id: 'N201',
		title: 'Query Service Fee',
		titleCN: '查询业务费用',
		endpoint: '/app/queryServiceFee',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['customerCurrency', 'txnAmt', 'txnCurrency', 'txnType'],
		respAttributes: [
			'fee',
			'feeCurrency',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
		],
	},
	{
		id: 'N202',
		title: 'Bakong Link Account Top Up Wallet',
		titleCN: 'Bakong关联账户充值',
		endpoint: '/app/bakongLinkAcctTopUpWallet',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: [
			'acctIdOUT',
			'assetId',
			'custId',
			'destAccountId',
			'externalTxnRefId',
			'note',
			'qrCode',
			'txnAt',
		],
		optionalAttributes: ['note', 'qrCode'],
		respAttributes: [
			'acctIdOUT',
			'acctNmOUT',
			'assetId',
			'currAvailAtOUT',
			'currBalAtOUT',
			'destAccountId',
			'destAccountName',
			'destBankName',
			'externalTxnRefId',
			'intTxnRefId',
			'intTxnSeqId',
			'lockAtOUT',
			'mobileOUT',
			'note',
			'originalTxnAt',
			'originalTxnCurrency',
			'srcBankName',
			'state',
			'txnAt',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnTm',
			'txnType',
		],
	},
	{
		id: 'N203',
		title: 'Query Card Info',
		titleCN: '查询卡片来源',
		endpoint: '/app/queryCardInfo',
		method: HTTP_METHODS.POST,
		tag: TAG.OTHERS,
		attributes: ['cardIdEnc'],
		respAttributes: ['region', 'currency'],
	},
];
