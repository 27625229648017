import { ENUMS } from '../enums';

export const OTHERS = [
	{
		key: 'zip',
		description: 'ZIP Code (Length Up to 20)',
		example: '12405',
		type: 'String',
	},
	{
		key: 'country',
		description: 'Country/Region/Organization',
		example: '',
		type: 'String',
	},
	{
		key: 'countryCode',
		description: 'Country Code',
		example: '',
		type: 'String',
	},
	{
		key: 'verCode',
		description: 'Verifcation Code (Length 6)',
		example: '',
		type: 'String',
	},
	{
		key: 'metaData',
		description: 'metaData',
		example: '',
		type: 'String',
	},
	{
		key: 'id',
		description: 'Transaction ID',
		example: '',
		type: 'String',
	},
	{
		key: 'rechargeId',
		description: 'Recharge ID',
		example: '',
		type: 'String',
	},
	{
		key: 'totFee',
		description: 'Total Handling Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'exchangeRateFee',
		description: 'Exchange Rate Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'amount',
		description: 'Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'originalAmount',
		description: 'Original Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'fee',
		description: 'Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'feeCurrency',
		description: 'Fee Currency',
		example: '840',
		type: 'String',
	},
	{
		key: 'settledAmt',
		description: 'Settled Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'settledWithCustomerAmt',
		description: 'Settled Amount Including Fee, and Exchange Rate Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'settledWithCustomerAmount',
		description: 'Settled Amount Including Fee, and Exchange Rate Fee',
		example: '',
		type: 'String',
	},
	{
		key: 'settledWithCustomerCurrency',
		description: 'Settled Currency',
		example: '840',
		type: 'String',
	},
	{
		key: 'frozen',
		description: 'Is the Account Frozen?',
		example: '',
		type: 'Boolean',
	},
	{
		key: 'crc',
		description: 'Checksum',
		example: '',
		type: 'String',
	},
	{
		key: 'timestamp',
		description: 'Timestamp',
		example: '',
		type: 'String',
	},
	{
		key: 'isValid',
		description: 'Is It Valid?',
		example: 'true',
		type: 'Boolean',
	},
	{
		key: 'rejectionReason',
		description: 'Reject Reason (Required If the Payment Status is Rejected)',
		example: '',
		type: 'String',
	},
	{
		key: 'businessIndication',
		description: 'Business Indication',
		example: '06',
		enum: ENUMS.businessIndicationEnum,
		type: 'String',
	},
	{
		key: 'fundSource',
		description: 'Fund Source',
		example: '0',
		enum: ENUMS.fundSourceEnum,
		type: 'String',
	},
	{
		key: 'origResponseCode',
		description: '',
		example: '',
		type: 'String',
	},
	{
		key: 'origResponseMsg',
		description: '',
		example: '',
		type: 'String',
	},
	{
		key: 'numCode',
		description: 'Numeric Code (Number, Length 3 Digits)',
		example: '156',
		type: 'String',
	},
	{
		key: 'withdrawAmount',
		description: 'Amount of Withdrawal',
		example: '',
		type: 'String',
	},
	{
		key: 'refundTransactionId',
		description: 'Refund Transaction ID',
		example: '',
		type: 'String',
	},
	{
		key: 'rootCause',
		description: 'Root Cause',
		example: '',
		enum: ENUMS.rootCauseEnum,
		type: 'String',
	},
	{
		key: 'orderNo',
		description: 'Order Number (Maximum Length 64)',
		example: '',
		type: 'String',
	},
	{
		key: 'dstCurrency',
		description: 'Destination Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'srcCurrency',
		description: 'Source Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'buyRate',
		description: 'Buy Rate',
		example: '',
		type: 'String',
	},
	{
		key: 'sellRate',
		description: 'Sell Rate',
		example: '',
		type: 'String',
	},
	{
		key: 'totNum',
		description: 'Number of Application Cards (Default 1)',
		example: '',
		type: 'String',
	},
	{
		key: 'referralCode',
		description: 'Referral Code',
		example: '',
		type: 'String',
	},
	{
		key: 'proRegNum',
		description: 'Replacement Card Working Number',
		example: '',
		type: 'String',
	},
	{
		key: 'mappingId',
		description: 'Customer Mapping ID',
		example: '',
		type: 'String',
	},
	{
		key: 'billNumber',
		description: 'Bill Number',
		example: '',
		type: 'String',
	},
	{
		key: 'location',
		description: 'Location',
		example: '',
		type: 'String',
	},
	{
		key: 'storeLabel',
		description: 'Store Label',
		example: '',
		type: 'String',
	},
	{
		key: 'terminalLabel',
		description: 'Terminal Label',
		example: '',
		type: 'String',
	},
	{
		key: 'userId',
		description: 'User ID',
		example: '',
		type: 'String',
	},
	{
		key: 'customerCurrency',
		description: "Customer's Account Currency or Card Currency",
		example: '',
		type: 'String',
	},
	{
		key: 'region',
		description: 'Region',
		example: '',
		enum: ENUMS.regionEnum,
		type: 'String',
	},
	{
		key: 'currency',
		description: 'Currency',
		example: '840',
		type: 'String',
	},
	{
		key: 'currencyCode',
		description: 'Currency',
		example: '840',
		type: 'String',
	},
	{
		key: 'purpose',
		description: 'Purpose',
		example: '',
		enum: ENUMS.purposeEnum,
		type: 'String',
	},
	{
		key: 'otherPurpose',
		description: 'Other Purpose',
		example: '840',
		type: 'String',
	},
	{
		key: 'systemResponse',
		description: 'System Response',
		example: '',
		type: 'String',
	},
	{
		key: 'initTxnId',
		description: 'Init Transaction ID',
		example: '',
		type: 'String',
	},
	{
		key: 'applicationOperator',
		description: 'Application Operator',
		example: '',
		type: 'String',
	},
	{
		key: 'validateBy',
		description: 'Validate By',
		example: '',
		type: 'String',
	},
	{
		key: 'validatedBy',
		description: 'Validated By',
		example: '',
		type: 'String',
	},
	{
		key: 'validationResult',
		description: 'Validation Result',
		example: '',
		type: 'String',
	},
];
