import { VALUES } from "../values";

export const OPERATOR = [
	{
		key: 'operatorId',
		description: '充值操作人员ID',
		example: '',
		type: 'String',
	},
	{
		key: 'operatorNm',
		description: '充值操作人员名称',
		example: '',
		type: 'String',
	},
	{
		key: 'appliOpr',
		description: '申请操作员 [最大长度20]',
		example: '',
		type: 'String',
	},
	{
		key: 'checkOpr',
		description: '审核操作员 [最大长度20]',
		example: '',
		type: 'String',
	},
	{
		key: 'note',
		description: '审核备注 [最大长度256]',
		example: '',
		type: 'String',
	},
	{
		key: 'notes',
		description: '审核备注 [最大长度256]',
		example: '',
		type: 'String',
	},
	{
		key: 'operationType',
		description: '操作类型',
		example: '',
		value: VALUES.operationTypeValue,
		type: 'String',
	},
];
