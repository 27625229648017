const endpoints = {
	namespaced: true,
	state: {
		endpoints: [],
	},
	mutations: {},
	actions: {},
	getters: {
		filterEndpoints_old: (state) => (include, exclude) => {
			if (include) {
				return state.endpoints.filter((e) => include.indexOf(e.endpoint) != -1);
			}

			if (exclude) {
				return state.endpoints.filter((e) => exclude.indexOf(e.endpoint) == -1);
			}

			return state.endpoints;
		},
		filterEndpoints: (state, getters, rootState) => (include, exclude, lang) => {
			const currentLanguage = lang || 'en';

			const attributes =
				require(`@/resources/${rootState.route.params.version}/${currentLanguage}/attributes`).ATTRIBUTES;

			const defaultAttributes =
				require(`@/resources/${rootState.route.params.version}/${currentLanguage}/attributes`).DEFAULT_ATTRIBUTE;

			const tagOrder = require('@/const/index').TAG_ORDER;

			const endpoints = require(`@/resources/${rootState.route.params.version}/endpoints`)
				.ENDPOINTS.sort(
					(a, b) =>
						tagOrder[a.tag.id] - tagOrder[b.tag.id] ||
						a.id.localeCompare(b.id, undefined, { sensitivity: 'base', numeric: true })
				)
				.map((e) => {
					return { ...e, attributes: e.attributes.sort(), respAttributes: e.respAttributes.sort() };
				})
				.map((e) => {
					return {
						...e,
						title: currentLanguage == 'cn' && e.titleCN ? e.titleCN : e.title,
						attri: e.attributes.map((item) => {
							const temp = attributes.find((e) => e.key == item) || {
								...defaultAttributes,
								key: item,
							};
							if (temp.type == 'Array' || temp.type == 'Object') {
								return {
									...temp,
									attri: temp.attributes.map(
										(i) => attributes.find((e) => e.key == i) || { ...defaultAttributes, key: i }
									),
								};
							}
							return temp;
						}),
						respAttri: e.respAttributes.map((item) => {
							const temp = attributes.find((e) => e.key == item) || {
								...defaultAttributes,
								key: item,
							};
							if (temp.type == 'Array') {
								return {
									...temp,
									attri: temp.attributes.map(
										(i) => attributes.find((e) => e.key == i) || { ...defaultAttributes, key: i }
									),
								};
							}
							return temp;
						}),
					};
				});

			console.log(
				`Total Number of APIs: ${endpoints.filter((endpoint) => endpoint.tag.id != 'NTF').length}`
			);

			console.log(
				`Total Number of Notifications: ${
					endpoints.filter((endpoint) => endpoint.tag.id == 'NTF').length
				}`
			);
			
			if (include) {
				return endpoints.filter((e) => include.indexOf(e.id) != -1);
			}

			if (exclude) {
				return endpoints.filter((e) => exclude.indexOf(e.id) == -1);
			}

			return endpoints;
		},
	},
};

export default endpoints;
