export const ENUMS = {
	keyIdTypeEnum: {
		0: 'Card Number',
		1: 'Account Number',
	},
	currencyEnum: {
		// '0001': 'EUR',
		// '0002': 'RMB',
		'0003': 'USD',
		'0004': 'Riel',
	},
	accountStatusEnum: {
		'00': 'Normal',
		'02': 'Frozen',
		'04': 'Closed',
	},
	cardStatusEnum: {
		'Virtual Card & Physical Card': {
			0: 'Normal',
			1: 'Frozen',
			2: 'Inactivated',
			3: 'Closed',
			5: 'Reviewing',
			6: 'Expired',
		},
		// 'Anonymous Card': {
		// 	0: 'Normal',
		// 	2: 'Enabled',
		// 	6: 'Card Expired',
		// 	7: 'Fund Expired',
		// 	9: 'Activated',
		// },
	},
	keyStatusEnum: {
		Account: {
			'00': 'Normal',
			'02': 'Frozen',
			'04': 'Closed',
		},
		Card: {
			0: 'Normal',
			1: 'Reported Lost',
			2: 'Inactivated',
			3: 'Logged Out',
			5: 'Reviewing (Specific for Card Replacement)',
			6: 'Expired',
		},
	},
	allocatedStatusEnum: {
		1: 'Approved',
		2: 'Rejected',
	},
	flagEnum: {
		Y: 'Uploaded',
		N: 'Not Uploaded',
	},
	applicationStatusEnum: {
		'Virtual Card': {
			'01': 'Pending',
			'06': 'Review Approved',
			'07': 'Review Rejected',
			'08': 'Review Approved, and Activated Successfully',
		},
		'Physical Card': {
			'PIN Based Card': {
				'01': 'Pending',
				'02': 'Request Submitted',
				'03': 'Request Failed',
				'04': 'Review Approved',
				'05': 'Review Rejected',
				'06': 'Approved',
				'07': 'Rejected',
				'08': 'Card/Account/Customer Information Establishment Completed',
				'09': 'Card/Account/Customer Information Establishment Failed',
			},
			'Signature Based Card': {
				'01': 'Pending',
				'06': 'Review Approved',
				'07': 'Review Rejected',
				'08': 'Printing Card',
				'09': 'Card Printed',
			},
		},
		'Request Form': {
			'01': 'Pending',
			'06': 'Review Approved',
			'07': 'Review Rejected',
		},
	},
	topUpTypeEnum: {
		'00': 'Cash',
		'01': 'Bank Deposit/Transfer',
		'04': 'App Top Up',
	},
	txnCategoryEnum: {
		1: 'Top Up',
		2: 'POS Consumption',
		3: 'Preauthorization',
		4: 'ATM Withdrawal',
		5: 'Transfer',
		6: 'Service Fee',
		// 7: 'Card Activation',
		8: 'Single Card Credit',
		9: 'Bakong Transfer',
		10: 'Money Express',
		11: 'Refund',
		12: 'Balance Adjustment',
		13: 'Bakong Link Account',
		14: 'Cashback',
	},
	transactionStatusCodeEnum: {
		Internal: {
			2: 'Success',
			3: 'Reversal',
			4: 'Cancelled',
			5: 'To be Completed',
		},
		Bakong: {
			0: 'Initialization',
			1: 'Transfer Successfully (Internal)',
			2: 'Transfer Successfully (Bakong)',
			3: 'Transfer Failed (Internal)',
			4: 'Transfer Failed (Bakong)',
			5: 'Transfer Successfully (Both Internal & Bakong)',
			6: 'Refund Successfully',
		},
	},
	txnTypeEnum: {
		B0010: 'Consumption',
		B0011: 'Single Card Consumption Cancellation',
		B0012: 'Single Card Consumption Reversal',
		B0013: 'Single Card Consumption Cancellation Reversal',
		B0020: 'Customer Consumption',
		B0030: 'Transfer',
		B0073: 'Refund',
		B0074: 'Withdraw',
		B0075: 'ATM Cash Withdrawal Reversal',
		B0080: 'Preauthorization Request',
		B0081: 'Preauthorization Reversal',
		B0082: 'Preauthorization Cancellation',
		B0083: 'Preauthorization Cancellation Reversal',
		B0084: 'Preauthorization Completion',
		B0085: 'Preauthorization Completion Reversal',
		B0086: 'Preauthorization Completion Cancellation',
		B0087: 'Preauthorization Completion Cancellation Reversal',
		C0010: 'Single Card Top Up',
		C0015: 'Single Card Credit',
		D0010: 'ATM Check Balance',
		ZA002: 'Portal Top Up',
		Z0030: 'Card Management Monthly Fee',
		Z0040: 'Card Management Annually Fee',
		B1030: 'Bakong Transfer',
		B1031: 'Bakong Transfer Reversal',
		B1032: 'Bakong Link Account Top Up',
		B2030: 'Money Express',
		B0032: 'Money Express Reversal',
		B0033: 'Money Express Cancellation',
		// B0061: 'Balance Adjustment Fail',
		B0062: 'Balance Adjustment Success',
		ZA005: 'Cashback',
	},
	cardTypeEnum: {
		1: 'Physical Card (Signature Base Card)',
		2: 'Virtual (The Default One is the Physical Signature Card)',
	},
	stateEnum: {
		'Internal Transfer & UMPS': {
			1: 'Successful',
		},
		Bakong: {
			0: 'Initialization',
			1: 'Transfer Successfully (Internal)',
			2: 'Transfer Successfully (Bakong)',
			3: 'Transfer Failed (Internal)',
			4: 'Transfer Failed (Bakong)',
			5: 'Transfer Successfully (Both Internal & Bakong)',
			6: 'Refund Successfully',
		},
		'Money Express': {
			0: 'Initialization',
			1: 'Successful',
			2: 'Failed',
			3: 'Pending',
		},
		'Money Express Init': {
			0: 'Initialization',
			1: 'Failed',
			2: 'Expired (The status of the initialization order applied for will be set as expired if it is not confirmed within 5 minutes)',
		},
		// Cancel: {
		// 	0: 'Initialization',
		// 	1: 'Refund Successfully',
		// 	2: 'Refund Failed',
		// },
	},
	businessIndicationEnum: {
		'06': 'Tax Refund',
		'07': 'Cross-border Remittance Outside Mainland China',
		'08': 'Domestic P2P Outside Mainland China',
		A1: 'Remittance Within China',
	},
	fundSourceEnum: {
		0: 'Cash',
		1: 'Debit Card',
		2: 'Credit Card',
		3: 'Account Funds',
	},
	documentTypeEnum: {
		1: 'ID Card',
		3: 'Passport',
		// 4: 'Business License',
	},
	cashflowTypeEnum: {
		1: 'Money In',
		2: 'Money Out',
	},
	brandIdEnum: {
		'0002': 'Bongloy Physical Card',
		'0003': 'Bongloy Virtual Card',
	},
	qrcodeCategoryEnum: {
		1: 'Chinese UPI',
		2: 'Overseas UPI',
	},
	qrcodeStateEnum: {
		11: 'Static',
		12: 'Dynamic',
	},
	bakongTypeEnum: {
		1: 'KHQR',
		2: 'Bakong Transfer',
	},
	payloadFormatIndicator: {
		'01': 'For This Version',
	},
	pointofInitiationMethodEnum: {
		11: 'Static QR Code',
		12: 'Dynamic QR Code',
	},
	upiStateEnum: {
		0: 'Inactivated',
		1: 'Activated',
	},
	transactionStatusEnum: {
		0: 'Initialization',
		1: 'Transfer Successfully (Internal)',
		2: 'Transfer Successfully (Bakong)',
		3: 'Transfer Failed (Internal)',
		4: 'Transfer Failed (Bakong)',
		5: 'Transfer Successfully (Both Internal & Bakong)',
		6: 'Refund Successfully',
	},
	rootCauseEnum: {
		ER1001: 'Account Spending Limit Reached',
		ER1002: 'Number of Account Spending Limit Reached',
		ER1003: 'Frozen Account',
		ER2001: 'Card Spending Limit Reached',
		ER2002: 'Number of Card Spending Limit Reached',
		ER2003: 'Frozen Card',
		ER2004: 'Wrong Card PIN',
	},
	physicalAppProgessEnum: {
		'01': 'In Review',
		'06': 'Approved',
		'07': 'Rejected',
		'08': 'Printing',
		'09': 'Printed',
	},
	yesNoEnum: {
		Y: 'Yes',
		N: 'No',
	},
	sexEnum: {
		0: 'Male',
		1: 'Female',
	},
	regionEnum: {
		0: 'Within China',
		1: 'Outside China',
		2: 'Cambodia',
	},
	purposeEnum: {
		1: 'Business Travel',
		2: 'Computer and Information Services',
		3: 'Construction and Installation',
		4: 'Dividends',
		5: 'Family Support',
		6: 'Freight Services Air',
		7: 'Government Services',
		8: 'Insurance Premium',
		9: 'Long Term Loan Repayment',
		10: 'Others',
		11: 'Payment for Goods',
		12: 'Personal Travel',
		13: 'Real Estate',
		14: 'Royalties and License Fees',
		15: 'Salary',
		16: 'Sea Freight',
		17: 'Securities',
		18: 'Short Term Loan Repayment',
		19: 'Telecommunication Service',
		20: 'Transfer to Own Account',
		21: 'Transportation',
	},
	acctCurrencyTypeEnum: {
		0: 'Default',
		1: 'KHR',
		2: 'USD',
	},
	balanceSymbolEnum: {
		C: 'Credit',
		D: 'Debit',
	},
};
